
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

@Component
export default class OrdersChart extends Vue {
  @Prop({ required: true }) private readonly ordersLabels: string[];
  @Prop({ required: true }) private readonly ordersData: number[];
  @Prop({ required: true }) private readonly ordersColors: string[];
  @Prop({ required: true }) private readonly isUpdated: boolean;

  private colors = this.$vuetify.theme.themes.light;
  private ordersInstance = null;
  private chartStructureData = {
    data: {
      labels: [],
      datasets: [
        {
          hidden: false,
          label: "Ordini per fornitore",
          data: [],
          backgroundColor: [],
          fill: true,
          pointBackgroundColor: this.colors.secondary["darken1"],
          borderColor: this.colors.secondary["lighten1"],
          pointRadius: 3,
          pointHitRadius: 15,
          borderWidth: 2,
          tension: 0.3,
        },
      ],
    },
  };
  private chartStructureOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          padding: 10,
          cornerRadius: 10,
          displayColors: false,
          backgroundColor: this.colors.brown["base"],
        },
        legend: {
          display: false,
        },
      },
    },
  };
  get total(): number {
    let total = 0;
    this.ordersData.forEach((data) => (total += data));
    return total;
  }

  mounted(): void {
    this.$set(
      this.chartStructureData.data.datasets[0],
      "data",
      this.ordersData
    );
    this.$set(this.chartStructureData.data, "labels", this.ordersLabels);
    this.$set(
      this.chartStructureData.data.datasets[0],
      "backgroundColor",
      this.ordersColors
    );
    this.createChart();
  }

  @Watch("isUpdated")
  private updateChart(): void {
    this.ordersInstance.update();
  }

  private createChart(): void {
    const canvas = document.getElementById("ordersChart") as HTMLCanvasElement;
    this.ordersInstance = new Chart(canvas, {
      type: "doughnut",
      data: this.chartStructureData.data,
      options: this.chartStructureOptions.options,
    });
  }
}
