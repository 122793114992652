
import { Component, Vue } from "vue-property-decorator";
import { dashboardData } from "@/services/api/dashboard-data.service";
import { DashboardSecondary } from "@/models/utils/dashboard-secondary.interface";
import { getModule } from "vuex-module-decorators";
import ReportChart from "./charts/ReportChart.vue";
import OrdersChart from "./charts/OrdersChart.vue";
import OverlayModule from "@/store/modules/overlay.module";
import store from "@/store";

const overlayModule = getModule(OverlayModule, store);

@Component({ components: { ReportChart, OrdersChart } })
export default class Dashboard extends Vue {
  private reportLoaded = false;
  private readonly reportLabels = [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre",
  ];
  private reportSelectedYear = new Date().getFullYear();
  private reportRevenues = [] as number[];
  private reportOrders = [] as number[];
  private reportOrdersAreHidden = false;
  private reportRevenuesAreHidden = false;

  private ordersLoaded = false;
  private isUpdated = false;
  private ordersSelectedYear = new Date().getFullYear();
  private ordersToFormat = [] as DashboardSecondary[];
  private ordersLabels = [] as string[];
  private ordersData = [] as number[];
  private ordersColors = [] as string[];

  get suppliersWithOrders(): DashboardSecondary[] {
    return this.ordersToFormat.filter((el) => el.orderCount > 0);
  }
  get totalReportRevenues(): string {
    let total = 0;
    this.reportRevenues.forEach((revenue) => (total += revenue));
    return this.formatInEuro(total);
  }
  get totalReportOrders(): number {
    let total = 0;
    this.reportOrders.forEach((cost) => (total += cost));
    return total;
  }
  get dataYears(): number[] {
    let dataYears = [];
    let actualYear = new Date().getFullYear();
    for (let i = 0; i <= 3; i++) {
      dataYears.push(actualYear - i);
    }
    return dataYears;
  }

  async mounted(): Promise<void> {
    Promise.all([this.getReportDataByYear(), this.getOrdersByYear()]);
  }

  private async getReportDataByYear(): Promise<void> {
    this.reportLoaded = false;
    overlayModule.showOverlay();
    const d = await dashboardData.getDataByYear(this.reportSelectedYear);
    this.reportRevenues = d.data.incomes;
    this.reportOrders = d.data.count;
    if (d) {
      this.reportLoaded = true;
      this.reportOrdersAreHidden = false;
      this.reportRevenuesAreHidden = false;
    }
  }

  private async getOrdersByYear(): Promise<void> {
    this.ordersLoaded = false;
    overlayModule.showOverlay();
    const d = await dashboardData.getOrdersByYear(this.ordersSelectedYear);
    this.ordersToFormat = d.data;
    this.formatOrdersData();
  }

  private formatInEuro(total: number): string {
    var formattedString = "";
    formattedString += new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(total);
    return formattedString;
  }

  private formatOrdersData(): void {
    if (this.ordersData) {
      this.ordersLabels = [] as string[];
      this.ordersData = [] as number[];
      this.ordersColors = [] as string[];
    }
    this.ordersToFormat.forEach((obj) => {
      if (obj.orderCount > 0) {
        this.ordersLabels.push(obj.companyName);
        this.ordersData.push(obj.orderCount);
        this.ordersColors.push(
          this.generateSupplierColor(`${obj.companyID}8566`)
        );
      }
    });
    this.ordersLoaded = true;
  }

  private generateSupplierColor(str: string): string {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = "#";
    for (i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  }

  private changeReportOrdersVisibility(): void {
    this.reportOrdersAreHidden = !this.reportOrdersAreHidden;
  }

  private changeReportRevenuesVisibility(): void {
    this.reportRevenuesAreHidden = !this.reportRevenuesAreHidden;
  }
}
