
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip
);

@Component
export default class Report extends Vue {
  @Prop({ required: true }) readonly reportLabels: string[];
  @Prop({ required: true }) readonly reportRevenues: number[];
  @Prop({ required: true }) readonly reportOrders: number[];
  @Prop({ required: true }) readonly reportOrdersAreHidden: boolean;
  @Prop({ required: true }) readonly reportRevenuesAreHidden: boolean;

  private colors = this.$vuetify.theme.themes.light;
  private reportChartInstance = null;

  private chartStructureData = {
    data: {
      labels: [],
      datasets: [
        {
          hidden: false,
          label: "Entrate",
          data: [],
          backgroundColor: `${this.colors.primary["lighten2"]}99`,
          fill: true,
          pointBackgroundColor: this.colors.primary["darken1"],
          borderColor: this.colors.primary["base"],
          pointRadius: 3,
          pointHitRadius: 15,
          borderWidth: 2,
          tension: 0.3,
        },
        {
          hidden: false,
          label: "Prodotti venduti",
          data: [],
          backgroundColor: this.colors.secondary["lighten1"],
          fill: true,
          pointBackgroundColor: this.colors.secondary["darken1"],
          borderColor: this.colors.secondary["lighten1"],
          pointRadius: 3,
          pointHitRadius: 15,
          borderWidth: 2,
          tension: 0.3,
        },
      ],
    },
  };

  private chartStructureOptions = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          min: 0,
          ticks: {
            display: true,
            maxTicksLimit: 6,
          },
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          padding: 8,
          cornerRadius: 8,
          displayColors: false,
          backgroundColor: this.colors.brown["base"],
          callbacks: {
            label: function (context) {
              var isOrder = false;
              var label = context.dataset.label || "";

              if (label && label == "Prodotti venduti") {
                label += ": ";
                isOrder = true;
              } else {
                label += ": ";
              }

              if (context.parsed.y !== null && isOrder == true) {
                return (label += context.parsed.y);
              } else {
                label += new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(context.parsed.y);
                return label;
              }
            },
          },
        },
        legend: {
          display: false,
        },
      },
    },
  };

  mounted(): void {
    this.$set(this.chartStructureData.data, "labels", this.reportLabels);
    this.$set(
      this.chartStructureData.data.datasets[0],
      "data",
      this.reportRevenues
    );
    this.$set(
      this.chartStructureData.data.datasets[1],
      "data",
      this.reportOrders
    );
    this.createChart();
  }

  @Watch("reportOrdersAreHidden")
  private onOrdersHiddenChange(actualValue: boolean): void {
    this.$set(this.chartStructureData.data.datasets[1], "hidden", actualValue);
    this.reportChartInstance.update();
  }

  @Watch("reportRevenuesAreHidden")
  private onRevenuesHiddenChange(actualValue: boolean): void {
    this.$set(this.chartStructureData.data.datasets[0], "hidden", actualValue);
    this.reportChartInstance.update();
  }

  // eslint-disable-next-line
  private createChart(): void {
    const canvas = document.getElementById("reportChart") as HTMLCanvasElement;
    this.reportChartInstance = new Chart(canvas, {
      type: "line",
      data: this.chartStructureData.data,
      options: this.chartStructureOptions.options,
    });
  }
}
