import { DashboardMain } from "@/models/utils/dashboard-main.interface";
import { DashboardSecondary } from "@/models/utils/dashboard-secondary.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "stats";

class DashboardService extends ApiService {
  getDataByYear(year: number) {
    return this.getAll<DashboardMain>(`${CONTROLLER}/primary/${year}`);
  }
  getOrdersByYear(year: number) {
    return this.getAll<DashboardSecondary[]>(`${CONTROLLER}/secondary/${year}`);
  }
}

export const dashboardData = new DashboardService();
